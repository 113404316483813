import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})

export class RestApiService {
  headerToken: any = "";
  public api_path;
  constructor(private httpClient: HttpClient) {
    if ("api_token" in sessionStorage) {
      this.headerToken = sessionStorage.getItem("api_token");
    }
  }

  post_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.post(path, body, {
      headers: headers
    });
  }
  delete_Api(URL) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.delete(path, {
      headers: headers
    });
  }
  
  put_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.put(path, body, {
      headers: headers
    });
  }

  post_Api_Call(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.post(path, body, {
      headers: headers,
      responseType: 'blob'
    });
  }

  get_API_New(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.get(path, {
      headers: headers
    });
  }

  get_API_Export(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.get(path, {
      headers: headers, responseType: 'arraybuffer'
    });
  }

  patch_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.patch(path, body, {
      headers: headers
    });
  }

  file_DownLoad_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;

    return this.httpClient.post(path, body, {
      responseType: "blob",
      headers: headers
    });
  }


  fileDownLoad_Api_GZip(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.post(path, body, {
      headers: headers, responseType: 'arraybuffer'
    });
  }

  file_Upload_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;

    return this.httpClient.put(path, body, {
      headers: headers
    });
  }

  file_Upload_Api_Post(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;

    return this.httpClient.post(path, body, {
      headers: headers
    });
  }
}
